import { useMemo } from 'react'
import Error from 'next/error'

import useRecommendationsAPI from './useRecommendationsAPI'
import { useApi } from '../useApi'
import { Product } from '../../utils/types/Product'
import { VertexAIUserEventType } from '../../utils/constants'
import { buildProductCodesQuery } from '../../utils/api'

interface UseRecommendedForYouParams {
  categoryName?: string
  supplierName?: string
  defaultEventType?: VertexAIUserEventType
  pageSize?: number
}

interface UseRecommendedForYouResult {
  data: Product[] | undefined
  isLoading: boolean
  error: Error
  triggerCategoryPageView: () => Promise<void>
  triggerDetailPageView: (productCode: string) => Promise<void>
  triggerHomePageView: () => Promise<void>
  triggerCartPageView: () => Promise<void>
}

/**
 * Excels at historical recommendations (~24h delay) but struggles with current
 * sessions due to strong past weighting. Supports filtering.
 *
 * @param params Parameters for the hook
 * @returns Result containing recommended products and functions to trigger events
 * Products will only load when a trigger event gets called.
 */
const useRecommendedForYou = (
  params: UseRecommendedForYouParams = {},
): UseRecommendedForYouResult => {
  const {
    categoryName,
    supplierName,
    defaultEventType = 'home-page-view',
    pageSize = 100,
  } = params

  const { getResource } = useApi()

  const modelType = 'recommendations-with-filters'

  const {
    data: recommendationsData,
    isLoading: isRecommendationsLoading,
    error: recommendationsError,
    triggerEvent,
  } = useRecommendationsAPI({
    modelType,
    categoryFilter: categoryName,
    supplierFilter: supplierName,
    defaultEventType,
    pageSize,
  })

  const productCodes = recommendationsData?.recommendations.map((rec) => rec.id) || []

  const query = useMemo(
    () => buildProductCodesQuery(productCodes, pageSize),
    [productCodes, pageSize],
  )

  // Use getResource outside of useEffect
  const {
    data: products,
    isLoading: isResourceLoading,
    error: resourceError,
  } = getResource<Product>('product', query || undefined)

  const triggerCategoryPageView = () => triggerEvent('category-page-view', {
    categoryFilter: categoryName,
    modelType,
  })

  const triggerDetailPageView = (productCode: string) => triggerEvent('detail-page-view', {
    associatedItems: [productCode],
    modelType,
  })

  const triggerHomePageView = () => triggerEvent('home-page-view')

  const triggerCartPageView = () => triggerEvent('shopping-cart-page-view')

  return {
    data: products,
    isLoading: isRecommendationsLoading || isResourceLoading,
    error: recommendationsError || resourceError,
    triggerCategoryPageView,
    triggerDetailPageView,
    triggerHomePageView,
    triggerCartPageView,
  }
}

export default useRecommendedForYou
